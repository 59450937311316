import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { useForm } from "react-hook-form";
import GetQuoteSection from "../components/GetQuoteSection";
import MapSection from "../components/MapSection";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import axios from "axios";
import { motion } from "framer-motion";
import { zoomInWithDelay } from "../animations/variants";
import TrailingParagraph from "../animations/TrailingParagraph";
import IntersectionBox from "../animations/IntersectionBox";
import { useStaticQuery, graphql } from "gatsby";

import { FaEnvelope, FaPhone, FaCompass } from "react-icons/fa";

function ContactUs() {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        contactUs {
          seo {
            meta_description
            meta_keywords
            title
          }
        }
      }
    }
  `);

  const node = data.strapi.contactUs;

  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();

  async function submit(data) {
    setSubmitting(true);
    try {
      const res = await axios.post(
        "https://api.custiv.com/api/forms/contact",
        data
      );
    } catch (error) {}
    console.log(data);
    setSubmitting(false);
    setSubmitted(true);
  }

  return (
    <Layout>
      <SEO
        title={node.seo.title}
        description={node.seo.meta_description}
        keywords={node.seo.meta_keywords}
      />
      <div className='get-in-touch'>
        <div className='content'>
          <p className='contact-head'>
            {" "}
            <TrailingParagraph paragraph='Get In Touch' />{" "}
          </p>
          <p className='contact-desc'>
            <TrailingParagraph paragraph='Learn how we can help you manufacture your product cheaper and faster. Enter your information below and we will get back to you soon.' />
          </p>
          <IntersectionBox>
            <div className='form-details'>
              {!submitted && (
                <form onSubmit={handleSubmit(submit)}>
                  <div className='form-subsection'>
                    <div className='top-row'>
                      <input
                        type='text'
                        name='name'
                        placeholder='Your Name'
                        id='fname'
                        ref={register({
                          required: {
                            value: true,
                            message: "Please input your name.",
                          },
                        })}
                      />
                      <input
                        type='email'
                        name='email'
                        placeholder='Your Email ID'
                        id='email'
                        ref={register({
                          required: {
                            value: true,
                            message: "Please input your email ID.",
                          },
                        })}
                      />
                    </div>
                    <div className='top-row'>
                      <input
                        type='text'
                        name='phone'
                        placeholder='Your Number'
                        id='phone'
                        ref={register({
                          required: {
                            value: true,
                            message: "Please input your phone number.",
                          },
                        })}
                      />
                      <input
                        type='text'
                        name='companyName'
                        placeholder='Company Name'
                        id='compname'
                        ref={register({
                          required: {
                            value: true,
                            message: "Please input your company name.",
                          },
                        })}
                      />
                    </div>
                    <div className='top-row'>
                      <textarea
                        name='enquiry'
                        placeholder='Message'
                        ref={register({
                          required: {
                            value: true,
                            message: "Please input your enquiry.",
                          },
                        })}></textarea>
                    </div>
                  </div>
                  <button
                    className='clippy_button request-button'
                    type='submit'>
                    {submitting ? (
                      <div class='lds-ellipsis'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      "Submit Request"
                    )}
                  </button>
                </form>
              )}
              {submitted && (
                <motion.div
                  className='contact-form-submitted'
                  initial='hidden'
                  animate='show'
                  variants={zoomInWithDelay(0.1)}>
                  <TrailingParagraph paragraph='Thank you for contacting Custiv. We have received your request. Our team will get back to you shortly.' />
                </motion.div>
              )}
            </div>
          </IntersectionBox>
        </div>
      </div>

      <div className='other-contacts'>
        <div className='content'>
          <p className='other-head'> Other ways of contacting us </p>
          <div className='row contact-row'>
            <div
              className='card card-1 col-lg-4 col-md-4 col-sm-4 col-xs-12 fadeIn'
              style={{ animationDelay: "750ms" }}>
              <div className='inner'>
                <div className='contact-icon'>
                  <FaEnvelope />
                </div>
                <p className='method-head'> Email Us </p>
                <div className='row'>
                  <div className='column column-1'>
                    <p className='method-desc'> Requirements: </p>
                    <p className='method-email'>
                      <a href='mailto:rfq@custiv.com'> rfq@custiv.com </a>
                    </p>
                    <p className='method-desc'> Support: </p>
                    <p className='method-email'>
                      <a href='mailto:support@custiv.com'>support@custiv.com</a>
                    </p>
                    <p className='method-desc'> Partnership: </p>
                    <p className='method-email'>
                      <a href='mailto:partner@custiv.com'>partner@custiv.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className='card card-2 col-lg-4 col-md-4 col-sm-4 col-xs-12 fadeIn'
              style={{ animationDelay: "1000ms" }}>
              <div className='inner'>
                <div className='contact-icon'>
                  <FaPhone />
                </div>
                <p className='method-head'> Call Us </p>
                <p className='method-desc'>
                  We will get back to you within 24 hours, or call us: Monday to
                  Saturday between 09:00 AM - 07:00 PM
                </p>
                <p className='contact-num'> +91-7349503587 </p>
              </div>
            </div>

            <div
              className='card card-3 col-lg-4 col-md-4 col-sm-4 col-xs-12 fadeIn'
              style={{ animationDelay: "1250ms" }}>
              <div className='inner'>
                <div className='contact-icon'>
                  <FaCompass />
                </div>
                <p className='method-head'> Reach Us </p>
                <p className='method-desc'>
                  Address: Custiv, Incubex HSR26, 656, 1131,
                  <br /> 22nd Cross Rd, Parangi Palaya, Sector 2, HSR Layout,
                  <br /> Bengaluru, Karnataka - 560102
                </p>
                <p className='locate-map'>
                  <a
                    href='https://maps.app.goo.gl/jrHwA6kwwoX7vmq97'
                    target='_blank'>
                    Locate us on map
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MapSection />
      <GetQuoteSection />
    </Layout>
  );
}

export default ContactUs;
